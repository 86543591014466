import React from 'react';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';

import {
  email,
  inner,
  imageWrap,
  links,
  next,
} from 'styles/pages/digitec/email.module.scss';

const Email1 = () => {
  return (
    <div className={classnames(email, 'scr-digitect-wrap')}>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <div className={inner}>
        <div className={imageWrap}>
          <img
            src="https://wp.screver.com/wp-content/uploads/2023/08/digitec-email-1_2.png"
            alt=""
          />

          <div className={classnames(links)}>
            <a
              href="https://go.screver.com/survey?token=118b877d-eef4-41ab-a911-cba10e265d4e&surveyItem=64cbc2725e8d6d76ad6ac6a1&value=1&lang=en"
              target="_blank"
              rel="noopener noreferrer"
            ></a>
            <a href="https://go.screver.com/survey?token=118b877d-eef4-41ab-a911-cba10e265d4e&surveyItem=64cbc2725e8d6d76ad6ac6a1&value=2&lang=en"></a>
            <a
              href="https://go.screver.com/survey?token=118b877d-eef4-41ab-a911-cba10e265d4e&surveyItem=64cbc2725e8d6d76ad6ac6a1&value=3&lang=en"
              target="_blank"
              rel="noopener noreferrer"
            ></a>
            <a
              href="https://go.screver.com/survey?token=118b877d-eef4-41ab-a911-cba10e265d4e&surveyItem=64cbc2725e8d6d76ad6ac6a1&value=4&lang=en"
              target="_blank"
              rel="noopener noreferrer"
            ></a>
            <a
              href="https://go.screver.com/survey?token=118b877d-eef4-41ab-a911-cba10e265d4e&surveyItem=64cbc2725e8d6d76ad6ac6a1&value=5&lang=en"
              target="_blank"
              rel="noopener noreferrer"
            ></a>
          </div>
        </div>

        <Link to="/cases/cx/digitec/email-2" className={next}>
          Next →
        </Link>
      </div>
    </div>
  );
};

export default Email1;
